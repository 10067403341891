import { Link } from 'react-router-dom';
import { Links } from './components/Links';
import logo from './assets/images/logo-2.png';
import logo_black_and_white from './assets/icons/logo-black-and-white.png';
import kratos_atreus from './assets/images/kratos_atreus.png';
import tv from './assets/images/tv.png';
import control from './assets/images/control.png';
import xbox_card from './assets/images/xbox_card.png';
import playstation_card from './assets/images/playstation_card.png';
import laptop from './assets/images/laptop.png';
import cellphones from './assets/images/cellphones.png';


function App() {
  return (
    <div className="landing content page">
        <Link to=''><img className="main-logo" src={logo} /></Link>
        <div className='intro-text'>
            <h2>Venha conferir as melhores <span>ofertas</span></h2>
            <p>Você pode acompanhar nossas oferta pelas redes também.</p>
        </div>
        <Links />
        <div className='landing-footer'>
            <div className='group'>
                <p>Grupo:</p>
                <img src={logo_black_and_white} className='logo' />
            </div>
            <div className='bottom-links row'>
                <Link to='privacy-politics'>Políticas de Privacidade</Link>
                <Link to='terms-and-conditions'>Termos e condições</Link>
                <Link to='contact'>Contato</Link>
            </div>
        </div>
        <div className='left-imgs'>
            <img className='kratos_atreus' src={kratos_atreus} />
            <img className='playstation_card' src={playstation_card} />
            <img className='xbox_card' src={xbox_card} />
            <img className='control' src={control} />
        </div>
        <div className='right-imgs'>
            <img className='tv' src={tv} />
            <img className='laptop' src={laptop} />
            <img className='cellphones' src={cellphones} />
        </div>
    </div>
  );
}

export default App;
